<template>
  <div class="daik_box">
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>绑定信息</span>
      </div>
    </div>
    <div class="pai_box">
      <div class="yinhka">
        <van-uploader :max-count="1" v-model="bankCard" :after-read="onRead">
          <!-- <img src="@/assets/images/sfzpz.png" alt="" /> -->
        </van-uploader>
        <span>银行卡正面</span>
      </div>
      <div class="yinhka">
        <van-uploader
          :max-count="1"
          v-model="identityCard"
          :after-read="afterRead"
        >
          <!-- <img src="@/assets/images/sfzpz.png" alt="" /> -->
        </van-uploader>
        <span>身份证正面</span>
      </div>
    </div>
    <div class="inxix">
      <van-field
        v-model="userFromData.aisle"
        @click="aisleshow"
        label="选择通道："
      />
      <van-field v-model="userFromData.realName" label="真实姓名：" />

      <van-field v-model="userFromData.identityNum" label="身份证号：" />
      <van-field
        v-model="userFromData.openBank"
        @click="openBlank"
        label="开户行："
      />
      <van-field v-model="userFromData.bankCarNum" label="银行卡号：" />
      <van-field v-model="userFromData.tell" label="手机号：" />
      <div class="btn">
        <van-button
          class="btnziji fanhui"
          type="default"
          size="large"
          @click="backxg"
          >返回修改</van-button
        >
        <van-button
          class="btnziji ljbd"
          type="primary"
          size="large"
          @click="affirmSign"
          >立即绑定</van-button
        >
      </div>
    </div>
    <!-- 选择通道的弹框 -->
    <van-popup v-model="showPopup" round position="bottom">
      <div class="td">
        <div
          class="tdyi"
          v-for="iotem in tdList"
          :key="iotem.id"
          @click="seletd(iotem)"
        >
          {{ iotem.channelName }}
        </div>
      </div>
    </van-popup>
    <!-- 结束 -->
    <!-- 开户行弹框 -->
    <van-picker
      title="请选择开户行"
      show-toolbar
      class="ingudn"
      :columns="columns"
      @confirm="onConfirm"
      @cancel="onCancel"
      @change="onChange"
      v-if="openBankflags"
    />
    <!-- 遮罩层 -->
    <div class="wefx" v-if="maskLayer"></div>
    <!-- 结束 -->
    <!-- 验证码弹框 -->
    <van-popup v-model="showyz" round class="insa">
      <van-field
        class="lsbes"
        v-model="yanzm"
        label="绑卡验证码："
        placeholder="请输入验证码"
      />
      <van-button class="subsime" size="normal" type="primary" @click="sublis"
        >确认</van-button
      >
    </van-popup>
    <!-- 结束 -->
    <van-loading
      class="loging"
      v-if="showloading"
      type="spinner"
      color="#1989fa"
      size="20"
    />
  </div>
</template>

<script>
import axiosins from "axios"; // 引入axios
import Exif from "exif-js";
import {
  getBankList,
  getPayChannelList,
  bindingBankCard,
  confirmBindBankCard,
  getFaceIdentityUrl,
} from "@/request/api";
export default {
  data() {
    return {
      userFromData: {
        aisle: "",
        aisleId: "",
        realName: "",
        identityNum: "",
        openBank: "",
        openBankId: "",
        bankCarNum: "",
        tell: "",
      },
      columns: [],
      zhifutdId: "",
      yanzm: "",
      showPopup: false,
      bankCard: [],
      identityCard: [],
      showyz: false,
      userMessage: "",
      tdList: [],
      maskLayer: false,
      showloading: false,
      openBankflags: false,
      certId: "", //绑卡时的id
      openBankList: "",
      typeLeixin: "",
      // 图片信息
      files: {
        name: "",
        type: "",
      },
      pictureFlag: true,
      sanlsublis: "",
      orderId: "",
    };
  },
  created() {
    this.getPayChannel();
  },
  mounted() {
    // 继续操作按钮跳转过来的
    const temporarysublis = this.$route.query.slysublis;
    const weslysublis = this.$route.query.weslysublis;

    let faceSwiping = this.$route.query.faceSwiping;
    if (temporarysublis && weslysublis) {
      //  合同的验证码
      this.sanlsublis = temporarysublis;
      this.showyz = true;
      this.certId = this.sanlsublis.certId;
      this.orderId = this.sanlsublis.orderId;
      console.log(this.sanlsublis);
      this.sublis(); // 验证码
    }
    // 1到刷脸
    if (faceSwiping == "1") {
      this.getFace();
    }
    // 继续操作跳转过来的结束

    const userMessage = this.$route.query.userMessage;
    this.userMessage = userMessage;
    const typeLeixin = this.$route.query.type;
    this.typeLeixin = typeLeixin;
    console.log(this.userMessage);
  },
  methods: {
    // 文件读取完的回调
    // beforeRead(file) {
    //   console.log(file.file);
    //   let redFlag = this.compress(file);
    //   console.log(redFlag);
    //   // return false
    // },

    // 银行卡上传图片
    onRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(1111111111111);
      console.log(file);
      // this.yhkOversize();
      console.log(file.file.size);
      this.files.name = file.file.name; // 获取文件名
      this.files.type = file.file.type; // 获取类型
      this.pictureFlag = true;
      this.imgPreview(file.file);
    },
    // 身份证上传图片
    afterRead(file) {
      console.log(file);
      console.log(file.file.size);
      this.files.name = file.file.name; // 获取文件名
      this.files.type = file.file.type; // 获取类型
      this.pictureFlag = false;
      this.imgPreview(file.file);
    },

    // 处理图片
    imgPreview(file) {
      let self = this;
      let Orientation;
      //去获取拍照时的信息，解决拍出来的照片旋转问题   npm install exif-js --save   这里需要安装一下包
      Exif.getData(file, function () {
        Orientation = Exif.getTag(this, "Orientation");
      });
      // 看支持不支持FileReader
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        // 创建一个reader
        let reader = new FileReader();
        // 将图片2将转成 base64 格式
        reader.readAsDataURL(file);
        // 读取成功后的回调
        reader.onloadend = function () {
          let result = this.result;
          let img = new Image();
          img.src = result;
          //判断图片是否大于600K,否就直接上传，反之压缩图片
          console.log(this.result.length);
          if (this.result.length <= 600 * 1024) {
            // 上传图片
            self.postImg(this.result);
          } else {
            img.onload = function () {
              let data = self.compress(img, Orientation);
              // 上传图片
              self.postImg(data);
            };
          }
        };
      }
    },

    // 压缩图片
    compress(img, Orientation) {
      // console.log(img, Orientation);
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      //瓦片canvas
      let tCanvas = document.createElement("canvas");
      let tctx = tCanvas.getContext("2d");
      // let initSize = img.src.length;
      let width = img.width;
      let height = img.height;
      //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio;
      if ((ratio = (width * height) / 4000000) > 1) {
        // console.log("大于400万像素");
        ratio = Math.sqrt(ratio);
        width /= ratio;
        height /= ratio;
      } else {
        ratio = 1;
      }
      canvas.width = width;
      canvas.height = height;
      //    铺底色
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      //如果图片像素大于100万则使用瓦片绘制
      let count;
      if ((count = (width * height) / 1000000) > 1) {
        // console.log("超过100W像素");
        count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
        //      计算每块瓦片的宽和高
        let nw = ~~(width / count);
        let nh = ~~(height / count);
        tCanvas.width = nw;
        tCanvas.height = nh;
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(
              img,
              i * nw * ratio,
              j * nh * ratio,
              nw * ratio,
              nh * ratio,
              0,
              0,
              nw,
              nh
            );
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height);
      }
      //修复ios上传图片的时候 被旋转的问题
      if (Orientation != "" && Orientation != 1) {
        switch (Orientation) {
          case 6: //需要顺时针（向左）90度旋转
            this.rotateImg(img, "left", canvas);
            break;
          case 8: //需要逆时针（向右）90度旋转
            this.rotateImg(img, "right", canvas);
            break;
          case 3: //需要180度旋转
            this.rotateImg(img, "right", canvas); //转两次
            this.rotateImg(img, "right", canvas);
            break;
        }
      }
      //进行最小压缩
      let ndata = canvas.toDataURL("image/jpeg", 0.2);
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
      // console.log(ndata);
      return ndata;
    },

    // 旋转图片
    rotateImg(img, direction, canvas) {
      //最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0;
      const max_step = 3;
      if (img == null) return;
      //img的高度和宽度不能在img元素隐藏后获取，否则会出错
      let height = img.height;
      let width = img.width;
      let step = 2;
      if (step == null) {
        step = min_step;
      }
      if (direction == "right") {
        step++;
        //旋转到原位置，即超过最大值
        step > max_step && (step = min_step);
      } else {
        step--;
        step < min_step && (step = max_step);
      }
      //旋转角度以弧度值为参数
      let degree = (step * 90 * Math.PI) / 180;
      let ctx = canvas.getContext("2d");
      switch (step) {
        case 0:
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0);
          break;
        case 1:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, 0, -height);
          break;
        case 2:
          canvas.width = width;
          canvas.height = height;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, -height);
          break;
        case 3:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, 0);
          break;
      }
    },

    //将base64转换为文件
    dataURLtoFile(dataurl) {
      var arr = dataurl.split(","),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      console.log(u8arr);
      return new File([u8arr], this.files.name, {
        type: this.files.type,
      });
    },

    // 提交图片到后端
    postImg(base64) {
      // console.log(base64);
      let file = this.dataURLtoFile(base64);
      console.log(file);
      // 提交图片
      if (this.pictureFlag) {
        this.showloading = true;
        this.maskLayer = true;
        var ininsData = new window.FormData(); //构造一个 FormData，把后台需要发送的参数添加
        ininsData.append("file", file); //接口需要传的参数
        console.log(ininsData);

        axiosins
          .post("http://fengshou.zjshuke.com:8085/ocr/bankCard", ininsData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log(res);
            if (res === undefined) {
              console.log(111);
              this.bankCard = [];
              this.showloading = false;
              this.maskLayer = false;
              this.userFromData.bankCarNum = "";
            }
            if (res.data.code == "200") {
              this.userFromData.bankCarNum = res.data.data.bankCardNo;
              this.showloading = false;
              this.maskLayer = false;
            } else {
              this.bankCard = [];
              this.showloading = false;
              this.maskLayer = false;
              this.userFromData.bankCarNum = "";
              this.$notify({ type: "warning", message: res.data.message });
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      } else {
        this.showloading = true;
        this.maskLayer = true;
        var insData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
        insData.append("file", file); //接口需要传的参数
        console.log(insData);
        axiosins
          .post("http://fengshou.zjshuke.com:8085/ocr/idCard", insData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log(res);
            if (res === undefined) {
              console.log(111);
              this.identityCard = [];
              this.showloading = false;
              this.maskLayer = false;
              this.userFromData.identityNum = "";
              this.userFromData.realName = "";
            }
            if (res.data.code == "200") {
              console.log(111);
              this.userFromData.identityNum = res.data.data.idNo;
              this.userFromData.realName = res.data.data.name;
              this.showloading = false;
              this.maskLayer = false;
            } else {
              this.userFromData.identityNum = "";
              this.userFromData.realName = "";
              this.identityCard = [];
              this.showloading = false;
              this.maskLayer = false;
              this.$notify({ type: "warning", message: res.data.message });
            }
          })
          .catch((err) => {});
      }
    },

    // 压缩图片

    // 通道弹框
    aisleshow() {
      this.showPopup = true;
    },
    // 开户行确认按钮
    onConfirm(value, index) {
      console.log(value, index + 1);
      this.openBankflags = false;
      this.maskLayer = false;
      this.openBankList.forEach((req) => {
        if (req.bankName == value) {
          console.log(req);
          this.userFromData.openBank = value;
          this.userFromData.openBankId = req.bankId;
          console.log(this.userFromData.openBankId);
        }
      });
    },
    // 开户行改变按钮
    onChange(picker, value, index) {
      // console.log(picker, value, index);
    },
    // 开户行取消按钮
    onCancel() {
      this.openBankflags = false;
      this.maskLayer = false;
    },
    // 打开 开户行的弹框
    openBlank() {
      console.log(111111);
      console.log(this.userFromData.aisle);
      if (this.userFromData.aisle) {
        this.openBankflags = true;
        this.maskLayer = true;
      }
    },
    goback() {
      if (this.typeLeixin == "1") {
        this.$router.push("/withholdData");
      } else {
        this.$router.push("/multiple");
      }
    }, 
    // 支付通道接口
    getPayChannel() {
      getPayChannelList().then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.tdList = res.data;
        }
      });
    },
    // 选择通道
    seletd(datas) {
      console.log(datas);
      this.showPopup = false;
      this.userFromData.aisle = datas.channelName;
      this.userFromData.aisleId = datas.id;
      this.zhifutdId = datas.id;
      console.log(this.zhifutdId);
      this.getBankListDate();
    },
    // 获取银行卡的列表
    getBankListDate() {
      console.log(this.zhifutdId);
      getBankList({ channelId: this.zhifutdId })
        .then((res) => {
          if (res.code == "200") {
            this.openBankList = res.data;
            res.data.forEach((req) => {
              this.columns.push(req.bankName);
            });
          }
        })
        .catch(() => {});
    },
    // 返回修改
    backxg() {
      // 返回单笔扣款
      if (this.userMessage.userId == "xx1") {
        this.$router.push({
          path: "withholdData",
          query: {
            userId: "xx1",
          },
        });
      }
      // 返回分期扣款
      if (this.userMessage.userId == "xx2") {
        this.$router.push({
          path: "multiple",
          query: {
            userId: "xx2",
          },
        });
      }
    },

    // 确认签署
    affirmSign() {
      //   this.bankCard.length > 0 &&
      // this.identityCard.length > 0
      console.log(this.userFromData);
      if (
        this.userFromData.aisle == "" ||
        this.userFromData.realName == "" ||
        this.userFromData.identityNum == "" ||
        this.userFromData.openBankId == "" ||
        this.userFromData.bankCarNum == "" ||
        this.userFromData.tell == ""
      ) {
        this.$notify({ type: "warning", message: "请输入完整的用户信息" });
      } else {
        console.log(this.userMessage);
        if (
          this.userMessage.deductionType == "" ||
          this.userMessage.money == "" ||
          this.userMessage.time == ""
        ) {
          this.$notify({
            type: "warning",
            message: "请确保上一步填写的有内容，请返回修改",
          });
        } else {
          var r = /^[0-9]+.?[0-9]*$/; //判断字符串是否为数字 //判断正整数 /^[1-9]+[0-9]*]*$/
          if (r.test(this.userFromData.bankCarNum) == false) {
            this.$notify({ type: "warning", message: "请输入规范的卡号" });
            return;
          }
          if (
            this.userFromData.bankCarNum.length < 16 ||
            this.userFromData.bankCarNum.length > 19
          ) {
            this.$notify({
              type: "warning",
              message: "银行卡号长度必须在16到19之间",
            });
            return;
          }
          var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
          //身份证号码规则校验
          if (!format.test(this.userFromData.identityNum)) {
            this.$notify({
              type: "warning",
              message: "身份证号码不合规",
            });
            return;
          }
          // 发送请求
          bindingBankCard({
            deductionType: this.userMessage.deductionType, //扣款的类型，1.单笔扣款，2.分期扣款
            amount: this.userMessage.money, //扣款金额
            beginTime: this.userMessage.time, //扣款开始时间
            stagesNumber: this.userMessage.installment, //分期的期数
            intervalTime: this.userMessage.cycle, //每一期的间隔时间
            intervalType: this.userMessage.value1, //间隔的类型，1.日,2.星期,3.月,4.年
            describe: this.userMessage.remark, //备注
            channelId: this.userFromData.aisleId, //支付通道id
            name: this.userFromData.realName, //姓名
            idCard: this.userFromData.identityNum, //身份证号
            bankId: this.userFromData.openBankId, //银行卡ID
            bankCard: this.userFromData.bankCarNum, //银行卡号
            mobile: this.userFromData.tell, //手机号
          }).then((res) => {
            console.log(res);
            if (res.code == "200") {
              let ordei = JSON.stringify(res.data.orderId);
              window.localStorage.setItem("withhold_orderId", ordei);
              if (res.data.phoneCode == true) {
                this.showyz = true;
                this.certId = res.data.certId;
                this.orderId = res.data.orderId;
              } else if (res.data.orderStatus == 2) {
                this.$router.push("/withholdList");
                this.$notify({
                  type: "success",
                  message: "订单创建成功",
                });
              } else {
                // 0 到合同签署 1到刷脸
                if (res.data.isFaceAuth == "1") {
                  this.getFace();
                } else {
                  this.$router.push({
                    path: "/codewait",
                    query: { sign: res.data, wedfe: true },
                  });
                }
              }
            } else {
              this.$notify({
                type: "warning",
                message: res.message,
              });
              this.$router.push("/xieyi");
            }
          });
        }
      }
    },
    // 验证码
    sublis() {
      confirmBindBankCard({
        certId: this.certId,
        orderId: this.orderId,
        code: this.yanzm,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          if (res.data.orderStatus == 2) {
            this.$notify({
              type: "success",
              message: "订单创建成功",
            });
            this.$router.push("/withholdList");
          } else {
            if (res.data.isFaceAuth == "1") {
              this.getFace();
            } else {
              this.$router.push("/yamCode?orderId=" + this.orderId);
            }
          }
          // this.$router.push("/yamCode");
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },

    getFace() {
      const codewait = localStorage.getItem("codewait");
      let lindomain = JSON.parse(codewait);
      console.log(lindomain);
      getFaceIdentityUrl({
        callbackUrl: lindomain,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          window.location.href = res.data;
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });

      // // 上面注释 是为了测试 跳过刷脸这一步
      // this.$router.push("/codeWait");
    },

    // 结束
  },
};
</script>

<style lang="scss" scoped>
.daik_box {
  .pai_box {
    width: 100%;
    height: 8rem;
    background: #f1f3f4;
    display: flex;
    .yinhka {
      display: flex;
      width: 50%;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 6rem;
        height: 4rem;
        margin-bottom: 0.5rem;
      }
      span {
        display: block;
        font-size: 0.8rem;
        text-align: center;
      }
    }
  }

  .inxix {
    // margin-top: .5rem;
    .btn {
      position: fixed;
      width: 100%;
      bottom: 0;
      .btnziji {
        width: 50%;
        display: inline-block;
      }
      .fanhui {
        // margin-right: .5rem;
        display: inline-block;
      }
      .ljbd {
        float: right;
      }
    }
  }
  .td {
    padding: 3rem;
    .tdyi {
      width: 100%;
      height: 2rem;
      // line-height: 3rem;
      border-bottom: 0.04rem solid #cccc;
      color: #3399cc;
    }
  }
  .insa {
    width: 85%;
    .lsbes {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .subsime {
      margin-top: 1rem;
      margin-left: 34%;
      width: 35%;
      margin-bottom: 1rem;
    }
  }
}
.wefx {
  position: absolute;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);
  width: 100%; /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
  height: 100%;
  filter: alpha(opacity=100); /*设置透明度为100%*/
  opacity: 1; /*非IE浏览器下设置透明度为100%*/
  display: block;
  z-index: 999;
}
.ingudn {
  position: fixed;
  width: 100%;
  height: 21rem;
  bottom: 0;
  z-index: 9999;
}
.loging {
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 9999;
}
/deep/ .van-popup--bottom.van-popup--round {
  height: 40%;
}
</style>
